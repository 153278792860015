<template>
  <div class="message-container" ref="messageContainer">
    <MessageArea
      :isListLoading="isLoading"
      title="留言板"
      :subTitle="`(${data.total})`"
      :list="data.rows"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import MessageArea from "@/components/MessageArea";
import { getMessage, postMessage } from "@/api/message.js";
import mainScroll from "@/mixins/mainScroll.js";
export default {
  components: {
    MessageArea,
  },
  mixins: [mainScroll("messageContainer")],
  data() {
    return {
      page: 1,
      limit: 10,
      data: {
        rows: [],
        total: 0,
      },
      isLoading: false,
    };
  },
  async created() {
    this.$bus.$on("mainScroll", this.handleScroll);
    await this.fetchData();
  },
  destroyed() {
    this.$bus.$off("mainScroll", this.handleScroll);
  },
  computed: {
    hasMore() {
      return this.data.rows.length < this.data.total;
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.data = await getMessage();
      this.isLoading = false;
    },
    async handleSubmit(data, callBack) {
      const res = await postMessage(data);
      callBack("感谢您的留言");
      this.fetchData();
      // this.data.rows.unshift(res);
    },
    handleScroll(dom) {
      if (this.isLoading || !dom) {
        return;
      }
      const range = 100;
      if (dom.scrollTop + dom.clientHeight + range >= dom.scrollHeight) {
        this.fetchMore();
      }
    },
    async fetchMore() {
      if (!this.hasMore) {
        return;
      }
      this.page++;
      this.isLoading = true;
      const res = await getMessage(this.page, this.limit);
      this.data.total = res.total;
      this.data.rows = this.data.rows.concat(res.rows);
      this.isLoading = false;
    },
  },
};
</script>
 
<style lang = "less" scoped>
.message-container {
  width: 100%;
  height: 100%;
  padding: 25px 0;
  overflow-y: auto;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.message-area-container {
  width: 1000px;
  margin: 0 auto;
}
</style>