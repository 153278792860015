<template>
  <ul class="data-list-container">
    <li v-for="item in list" :key="item.id">
      <Avatar :url="item.avatar" class="avatar-container" :size="44" />
      <div class="data">
        <div class="nickname">{{ item.nickname }}</div>
        <div class="content">{{ item.content }}</div>
        <div class="time">{{ formatDate(item.createDate, true) }}</div>
      </div>
    </li>
  </ul>
</template>

<script>
import Avatar from "@/components/Avatar";
import formatDate from "@/utils/formatDate";
export default {
  components: {
    Avatar,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    formatDate,
  },
};
</script>
 
<style lang = "less" scoped>
@import "~@/styles/var.less";
.data-list-container {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid lighten(@gray, 20%);
}
.avatar-container {
  margin-right: 15px;
}

.nickname {
  color: darken(@success, 10%);
  margin-bottom: 10px;
}

.content {
  font-size: 14px;
}

.data {
  position: relative;
  flex: 1 1 auto;
}

.time {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 12px;
  color: @gray;
}
</style>